<template>
  <Page>
    <v-snackbar v-model="snackbar" :timeout="3000" color="red">
      This link is invalid or expired
    </v-snackbar>
    <loading-circle v-if="loading" />
  </Page>
</template>
<script>
import axios from 'axios';
import LoadingCircle from '@/components/LoadingCircle.vue';
import * as base64 from 'base-64';
export default {
  components: { LoadingCircle },
  data() {
    return {
      snackbar: false,
      id: null,
      loading: false
    };
  },
  computed: {
    token() {
      const token = this.$route?.params?.token;
      return token[token.length - 1] !== '='
        ? token.substring(0, token.length - 1)
        : token;
    }
  },
  methods: {
    async callMagicLinkEndpoint() {
      await axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/v1/connect/auth/magicLink`,
          {
            id: this.$route?.params?.id
          },
          {
            headers: {
              'x-api-key':
                '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
            }
          }
        )
        .then((response) => {
          this.$router.push({
            name: 'ValidateExternalLogin',
            params: { token: response.data.token }
          });
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    },
    async callMagicLinkLoginEndpoint() {
      await axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/v1/connect/auth/magicLinkLogin`,
          {
            id: this.$route?.params?.id
          },
          {
            headers: {
              'x-api-key':
                '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
            }
          }
        )
        .then((response) => {
          this.$router.push({
            name: 'ValidateExternalLogin',
            params: {
              token: response.data.token,
              route: this.$route?.params?.route
            }
          });
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
        });
    },
    async validationLink() {
      await axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/v1/connect/auth/tokenValidation`,
          {
            token: this.token
          },
          {
            headers: {
              'x-api-key':
                '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
            }
          }
        )
        .then((response) => {
          var pid = base64.decode(this.$route?.params?.id);

          if (response && response.data) {
            if (pid.toString() == response.data.pid.toString()) {
              this.callMagicLinkLoginEndpoint();
            } else {
              this.snackbar = true;
              setTimeout(() => {
                this.snackbar = false;
                this.$router.push({ name: 'Login' });
              }, 3000);
            }
          } else {
            this.snackbar = true;
            setTimeout(() => {
              this.snackbar = false;
              this.$router.push({ name: 'Login' });
            }, 3000);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
        });
    }
  },
  async mounted() {
    this.loading = true;
    if (this.token) {
      this.validationLink();
    } else {
      this.callMagicLinkEndpoint();
    }
  }
};
</script>
